import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Seo from "../components/Seo";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { StaticImage } from "gatsby-plugin-image";

const Error404 = () => {
  return (
    <Layout>
      <Seo title="404" />
      <Container fluid="lg">
        <Row>
          <Col xl={3} lg={3} md={4} sm={6} xs={12} className="wavebackground">
            <div>
              <StaticImage
                src="../images/nathan-dumlao-a3ra9eXUjvo-unsplash.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                loading="eager"
                alt="Photo by Nathan Dumlao on Unsplash"
                draggable="false"
                width={500}
              />
            </div>
          </Col>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc="Ошибка 404" />
            <Alert
              variant="danger"
              className="animate__animated animate__pulse animate__fast"
            >
              <Alert.Heading>
                Что-то пошло не так! <br />
                Страница не найдена! <br />
                Начинаем паниковать!
              </Alert.Heading>
              <p>
                Как быть?
                <br />
                Что делать?
                <br />
                Как поступить?
              </p>
            </Alert>
            <p>
              К сожалению, страница, которую вы ищете, <b>была удалена</b> или{" "}
              <b>перемещена</b>.
            </p>
            <p>
              Или, возможно, вы <b>неверно набрали адрес...</b>.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Error404;
